module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MYRMedia","short_name":"MYRMedia","start_url":"/","background_color":"#ffffff","theme_color":"#353e4d","display":"standalone","icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"myrmedia","previews":true,"path":"/preview","accessToken":"MC5Yay1yalJBQUFDVUFtcURM.77-977-9Nu-_ve-_vQ1MYRUMe--_ve-_ve-_vU3vv73vv71b77-977-9Iu-_vS1x77-9bHrvv70p77-977-9ZA","defaultLang":"en-us"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
